<template lang="pug">
.content
  section.container-fluid.m-t-20

    a-breadcrumb(separator=">")
      a-breadcrumb-item
        router-link(to="/") 控制台
      a-breadcrumb-item
        router-link(to="/product") 产品管理
      a-breadcrumb-item 分类管理
    
    .m-t-15.m-b-20
      h1.section-title.inline 分类管理
      a-button.m-l-15(size="small" @click="handleAdd") 新增分类

    vc-table.m-b-25(
      ref="table"
      :data="table"
      @change="handleTableChange")
    
    //- 分类标签表单
    vc-taxonomy-form(
      ref="TaxonomyForm"
      :modal="modal_form"
      @ok="() => this.$refs.table.refresh()")
</template>

<script>
//- import { mapState } from 'vuex'

import columns from '@/config/column.config'
import vcTable from '@/components/vcTable'
import vcTaxonomyForm from '@/components/vcTaxonomy/vcTaxonomyForm'

export default {
  name: 'ProductCategoryManagement',
  components: {
    'vc-table': vcTable,
    'vc-taxonomy-form': vcTaxonomyForm
  },
  computed: {
    //- ...mapState({
    //-   ui: state => state.ui
    //- })
  },
  data() {
    return {
      /*********************************************************
      * @description vc-taxonomy-form 对象
      *********************************************************/
      modal_form: {
        api: {
          get: id => this.$http.get(`product_category/${id}`),
          set: params => {
            const { id } = params;
            const method = id ? 'put' : 'post';
            const url = id ? `product_category/${id}`: 'product_category';
            return this.$http[method](url, params);
          }
        },

        options: {
          taxonomy: 'product_category',
          hierarchy: true,
          sort: true,
          //- gallery: {
          //-   label: '图标',
          //-   preview: {
          //-     icon: {
          //-       //- type: 'user',
          //-       //- color: '',
          //-       size: '21px'
          //-     },
          //-     label: '图标上传',
          //-     //- size: [ 100, 100 ]
          //-   },
          //-   accept: ['.png'],
          //-   multiple: true,
          //-   maxfile: 2,
          //-   //- size: {
          //-   //-   width: 50,
          //-   //-   height: 20
          //-   //- },
          //-   key: 'gallery',

          //-   extra_data: { app: 'admin', module: 'product_category' },
          //-   tip: '尺寸大小建议 50 × 20 像素'
          //- }
        }
      },

      // 查询条件参数
      queryParam: {},

      /*********************************************************
      * @description vc-table 表格对象
      *********************************************************/
      table: {
        loading: false,
        size: 'middle',
        columns: columns.productCategory(this),
        source: [],

        api: parameter => {
          return this.$http.get('product_category', {
            params: Object.assign(parameter, this.queryParam)
          });
        },
      
        // 批量操作
        actions: [
          { label: '删除', value: 'delete' },
        ],
        
        // 分页器
        pagination: {
          total: 0,
          current: 1,
          pageSize: 15,
          pageSizeOptions: ['15', '25', '35'],
        },
      },
    }
  },
  methods: {
    /*********************************************************
     * @description vc-table 对象方法
     *********************************************************/
    async handleTableChange({ action }) {
      if (action) {
        switch(action.do) {
          case 'delete':
            await this.deleteDatas(action.data);
          break;
        }
      }
    },

    //- 新增/编辑操作方法
    handleAdd() {
      const route = this.$router.getRoutes().find(item => item.name === 'Product Category Add');
      if (route) this.$router.push({ name: 'Product Category Add' });
      
      this.$refs.TaxonomyForm.open('新增分类');
    },
    async handleEdit(id) {
      const route = this.$router.getRoutes().find(item => item.name === 'Product Category Edit');
      if (route && this.$route.name !== 'Product Category Edit') return this.$router.push({ name: 'Product Category Edit', params: { id } });
      
      this.$refs.TaxonomyForm.edit('编辑分类', id);
    },

    /*********************************************************
     * @description 删除单个/多个数据
     *********************************************************/
    async deleteData(id) {
      try {
        await this.$http.delete(`product_category/${id}`);
        this.$message.success('删除成功');
        this.$refs.table.refresh();
      } catch(err) {
        this.$message.error(err.message);
      }
    },
    async deleteDatas(ids) {
      try {
        await this.$http.delete('product_category', {
          data: { ids }
        });
        this.$message.success('删除成功');
        this.$refs.table.refresh();
      } catch(err) {
        this.$message.error(err.message);
      }
    }
  },
  created() {},
}
</script>